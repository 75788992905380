export type SudokuTable = number[][][]
type pos = {
    i: number
    j: number
}

export function set_all_note(sudoku_arr: SudokuTable): void {
    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            if (sudoku_arr[i][j][0] === 0) {
                sudoku_arr[i][j] =[1,2,3,4,5,6,7,8,9]
            }
        }
    }
}

export  function delete_row_simple(sudoku_arr: SudokuTable, to_delete: number[], i: number, j: number): boolean {
    let deleted: boolean = false

    for (let a = 0; a < sudoku_arr[i].length; a++) {
            const old_len = sudoku_arr[i][a].length
            if (to_delete.length === 1) {
                if (!(sudoku_arr[i][a].length === 1 && sudoku_arr[i][a][0] === to_delete[0])) {
                    sudoku_arr[i][a] = sudoku_arr[i][a].filter(num => num !== to_delete[0])
                }
            } else if (to_delete.length === 2) {
                if (!(sudoku_arr[i][a].length === 2 && sudoku_arr[i][a][0] === to_delete[0] && sudoku_arr[i][a][1] === to_delete[1])) {
                    sudoku_arr[i][a] = sudoku_arr[i][a].filter(num => num !== to_delete[0])
                    sudoku_arr[i][a] = sudoku_arr[i][a].filter(num => num !== to_delete[1])
                }
            }
            const new_len = sudoku_arr[i][a].length

            if (old_len !== new_len) {
                deleted = true;
            }
    }
    return deleted;
}

export  function delete_row_filter(sudoku_arr: SudokuTable, to_delete: number[], filter_cell: number[], i: number): boolean {
    let deleted: boolean = false

    for (let j = 0; j < sudoku_arr[i].length; j++) {
        if (!filter_cell.includes(j)) {
            if (to_delete.length === 1) {
                const old_len = sudoku_arr[i][j].length
                if (sudoku_arr[i][j].length !== 1) {
                    sudoku_arr[i][j] = sudoku_arr[i][j].filter(num => num !== to_delete[0])
                }
                const new_len = sudoku_arr[i][j].length

                if (old_len !== new_len) {
                    deleted = true;
                }
            }
            if (to_delete.length === 3) {
                const old_len = sudoku_arr[i][j].length
                if (sudoku_arr[i][j].length !== 1) {
                    sudoku_arr[i][j] = sudoku_arr[i][j].filter(num => num !== to_delete[0])
                    sudoku_arr[i][j] = sudoku_arr[i][j].filter(num => num !== to_delete[1])
                    sudoku_arr[i][j] = sudoku_arr[i][j].filter(num => num !== to_delete[2])
                }
                const new_len = sudoku_arr[i][j].length

                if (old_len !== new_len) {
                    deleted = true;
                }
            }
        }
    }
    return deleted
}

export  function delete_col_filter(sudoku_arr: SudokuTable, to_delete: number[], filter_cell: number[], j: number): boolean {
    let deleted: boolean = false

    for (let i = 0; i < sudoku_arr.length; i++) {
        if (!filter_cell.includes(i)) {
            if (to_delete.length === 1) {
                const old_len = sudoku_arr[i][j].length
                if (sudoku_arr[i][j].length !== 1) {
                    sudoku_arr[i][j] = sudoku_arr[i][j].filter(num => num !== to_delete[0])
                }
                const new_len = sudoku_arr[i][j].length

                if (old_len !== new_len) {
                    deleted = true;
                }
            }
        }
    }

    return deleted
}

export  function delete_sqr_filter(sudoku_arr: SudokuTable, to_delete: number[], filter_cell: pos[], sqr_index: number): boolean {
    let deleted: boolean = false

    const square_start_i_index = Math.floor(sqr_index / 3) * 3
    const square_start_j_index = sqr_index % 3 * 3

    for (let a = 0; a < 3; a++) {
        for (let b = 0; b < 3; b++) {
            const i = square_start_i_index + a
            const j = square_start_j_index + b


            if (!(filter_cell.some(pos => pos.i === i && pos.j === j) || sudoku_arr[i][j].length === 1)) {
                const old_len = sudoku_arr[i][j].length
                sudoku_arr[i][j] = sudoku_arr[i][j].filter(item => !to_delete.includes(item));
                const new_len = sudoku_arr[i][j].length


                if (old_len !== new_len) {
                    deleted = true

                }

            }
        }
    }


    return deleted
}

export  function delete_col_simple(sudoku_arr: SudokuTable, to_delete: number[], i: number, j: number): boolean {
    let deleted: boolean = false

    for (let a = 0; a < sudoku_arr.length; a++) {
        const old_len = sudoku_arr[a][j].length

        if (to_delete.length === 1) {
            if (!(sudoku_arr[a][j].length === 1 && sudoku_arr[a][j][0] === to_delete[0])) {
                sudoku_arr[a][j] = sudoku_arr[a][j].filter(num => num !== to_delete[0])
            }
        } else if (to_delete.length === 2) {
            if (!(sudoku_arr[a][j].length === 2 && sudoku_arr[a][j][0] === to_delete[0] && sudoku_arr[a][j][1] === to_delete[1])) {
                sudoku_arr[a][j] = sudoku_arr[a][j].filter(num => num !== to_delete[0])
                sudoku_arr[a][j] = sudoku_arr[a][j].filter(num => num !== to_delete[1])
            }
        }

        const new_len = sudoku_arr[a][j].length

        if (old_len !== new_len) {
            deleted = true;
        }
    }
    return deleted;
}

export  function delete_square_simple(sudoku_arr: SudokuTable, to_delete: number[], i: number, j: number): boolean {
    let deleted: boolean = false

    for (let a = 0; a < 3; a++) {
        for (let b = 0; b < 3; b++) {
            const pos_i = Math.floor(i / 3) * 3 + a
            const pos_j = Math.floor(j / 3) * 3 + b

            const old_len = sudoku_arr[pos_i][pos_j].length


            if (to_delete.length === 1) {
                if (!(sudoku_arr[pos_i][pos_j].length === 1 && sudoku_arr[pos_i][pos_j][0] === to_delete[0])) {
                    sudoku_arr[pos_i][pos_j] = sudoku_arr[pos_i][pos_j].filter(num => num !== to_delete[0])
                }
            } else if (to_delete.length === 2) {
                if (!(sudoku_arr[pos_i][pos_j].length === 2 && sudoku_arr[pos_i][pos_j][0] === to_delete[0] && sudoku_arr[pos_i][pos_j][1] === to_delete[1])) {
                    sudoku_arr[pos_i][pos_j] = sudoku_arr[pos_i][pos_j].filter(num => num !== to_delete[0])
                    sudoku_arr[pos_i][pos_j] = sudoku_arr[pos_i][pos_j].filter(num => num !== to_delete[1])
                }
            }

            const new_len = sudoku_arr[pos_i][pos_j].length

            if (old_len !== new_len) {
                deleted = true;
            }
        }
    }
    return deleted;
}

export  function check_correct_answer(sudoku_arr: SudokuTable, answer: string) {
    let len_total = 0;

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            len_total += sudoku_arr[i][j].length
        }
    }
    if (len_total !== 81) {
        return
    }





    let string_resonse = ''

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            string_resonse += sudoku_arr[i][j]

        }
    }

    if (answer === string_resonse) {
        console.log("good answer");
    } else {
        console.log("bad answer");

    }

}

// sudoku functioms
export  function set_alone(sudoku_arr: SudokuTable): boolean {
    let to_delete = 0;
    let deleted = false;

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            if (sudoku_arr[i][j].length === 1) {
                to_delete = sudoku_arr[i][j][0]

                if (delete_col_simple(sudoku_arr, [to_delete], i, j)) {
                    deleted = true
                    return deleted
                }

                if (delete_row_simple(sudoku_arr, [to_delete], i, j)) {
                    deleted = true
                    return deleted
                }

                if (delete_square_simple(sudoku_arr, [to_delete], i, j)) {
                    deleted = true
                    return deleted
                }
            }
        }
    }
    return deleted;
}

export  function find_alone_row(sudoku_arr: SudokuTable): boolean {
    let set_new = false;

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            if (sudoku_arr[i][j].length !== 1) {
                for (let k = 0; k < sudoku_arr[i][j].length; k++) {
                    let contain = false;
                    for (let a = 0; a < sudoku_arr[i].length; a++) {
                        if (a !== j) {
                            if (sudoku_arr[i][a].includes(sudoku_arr[i][j][k])) {
                                contain = true;
                                break;
                            }
                        }
                    }

                    if (contain === false) {
                        sudoku_arr[i][j] = [sudoku_arr[i][j][k]]
                        set_new = true;
                    }
                }
            }
        }
    }
    return set_new;
}
export  function find_alone_col(sudoku_arr: SudokuTable): boolean {
    let set_new = false;

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            if (sudoku_arr[i][j].length !== 1) {
                for (let k = 0; k < sudoku_arr[i][j].length; k++) {
                    let contain = false;

                    for (let a = 0; a < sudoku_arr.length; a++) {
                        if (a !== i) {
                            if (sudoku_arr[a][j].includes(sudoku_arr[i][j][k])) {
                                contain = true;
                                break;
                            }
                        }
                    }
                    if (contain === false) {
                        sudoku_arr[i][j] = [sudoku_arr[i][j][k]]
                        set_new = true;
                        return set_new
                    }
                }
            }
        }
    }
    return set_new;
}

export  function find_alone_sqare(sudoku_arr: SudokuTable): boolean {
    let set_new = false;

    for (let square_index = 0; square_index < 9; square_index++) {

        for (let number = 1; number < 10; number++) {
            let num_num = 0;

            const square_start_i_index = Math.floor(square_index / 3) * 3
            const square_start_j_index = square_index % 3 * 3

            let save_new_i = 0;
            let save_new_j = 0;

            for (let i = 0; i < 3; i++) {
                for (let j = 0; j < 3; j++) {
                    const new_i = square_start_i_index + i
                    const new_j = square_start_j_index + j
                    if (sudoku_arr[new_i][new_j].length !== 1 && sudoku_arr[new_i][new_j].includes(number)) {
                        num_num++
                        save_new_i = new_i
                        save_new_j = new_j
                    }
                }
            }
            if (num_num === 1) {
                sudoku_arr[save_new_i][save_new_j] = [number]
                return true;
            }
        }
    }

    return set_new
}

export  function set_duo_row(sudoku_arr: SudokuTable): boolean {
    let tmp_arr = []
    let has_duo = false
    let deleted = false;

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            if (sudoku_arr[i][j].length === 2) {
                tmp_arr = sudoku_arr[i][j]

                // see row
                for (let a = 0; a < sudoku_arr[i].length; a++) {
                    if (a !== j) {
                        if (sudoku_arr[i][a].length === 2 && sudoku_arr[i][a][0] === tmp_arr[0] && sudoku_arr[i][a][1] === tmp_arr[1]) {
                            has_duo = true;
                        }
                    }
                }

                // if has duo, delete duo
                if (has_duo) {
                    if (delete_row_simple(sudoku_arr, tmp_arr, i, j)) {
                        deleted = true
                        return deleted
                    }
                }
                has_duo = false
            }

        }

    }
    return deleted
}

export  function set_duo_col(sudoku_arr: SudokuTable): boolean {
    let tmp_arr = []
    let has_duo = false
    let deleted = false;

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            if (sudoku_arr[i][j].length === 2) {
                tmp_arr = sudoku_arr[i][j]

                for (let a = 0; a < sudoku_arr.length; a++) {
                    if (a !== i) {
                        if (sudoku_arr[a][j].length === 2 && sudoku_arr[a][j][0] === tmp_arr[0] && sudoku_arr[a][j][1] === tmp_arr[1]) {
                            has_duo = true;
                        }
                    }
                }

                if (has_duo) {
                    if (delete_col_simple(sudoku_arr, tmp_arr, i, j)) {
                        deleted = true
                        return deleted
                    }
                }
                has_duo = false
            }
        }
    }
    return deleted
}

export  function set_duo_square(sudoku_arr: SudokuTable) {
    let tmp_arr = []
    let has_duo = false
    let deleted = false;

    for (let i = 0; i < sudoku_arr.length; i++) {
        for (let j = 0; j < sudoku_arr[i].length; j++) {
            if (sudoku_arr[i][j].length === 2) {
                tmp_arr = sudoku_arr[i][j]

                for (let a = 0; a < 3; a++) {
                    for (let b = 0; b < 3; b++) {
                        const pos_i = Math.floor(i / 3) * 3 + a
                        const pos_j = Math.floor(j / 3) * 3 + b

                        if (!(pos_i === i && pos_j === j)) {
                            if (sudoku_arr[pos_i][pos_j].length === 2 && sudoku_arr[pos_i][pos_j][0] === tmp_arr[0] && sudoku_arr[pos_i][pos_j][1] === tmp_arr[1]) {
                                has_duo = true
                            }
                        }
                    }
                }
                if (has_duo) {
                    if (delete_square_simple(sudoku_arr, tmp_arr, i, j)) {
                        deleted = true
                        return deleted
                    }
                }
                has_duo = false

            }

        }

    }
    return deleted
}

export  function check_one_line_note_row(sudoku_arr: SudokuTable) {
    let deleted: boolean = false;

    // loop for 9 quare
    for (let i = 0; i < 9; i++) {

        let new_i = Math.floor(i / 3) * 3;
        let new_j = i % 3 * 3;

        // loop for 9 numbers
        for (let c = 0; c < 9; c++) {
            let row_num_sum = 0;
            let row_index = 0
            let row_contain_cell:number[] = [];

            // loop for row in a square
            for (let a = 0; a < 3; a++) {
                let one_line_num = 0

                // loop for col in a row
                for (let b = 0; b < 3; b++) {
                    const pos_i = Math.floor(new_i / 3) * 3 + a
                    const pos_j = Math.floor(new_j / 3) * 3 + b

                    if (sudoku_arr[pos_i][pos_j].length !== 1 && sudoku_arr[pos_i][pos_j].includes(c + 1)) {
                        one_line_num++
                        row_contain_cell.push(pos_j)
                    }
                }
                if (1 <= one_line_num) {
                    one_line_num = 0;
                    row_num_sum++;
                    row_index = a
                }
            }
            if (row_num_sum === 1) {
                if (delete_row_filter(sudoku_arr, [c + 1], row_contain_cell, new_i + row_index)) {
                    deleted = true
                    return deleted
                }
            }
            row_num_sum = 0
            row_index = 0
            row_contain_cell = []
        }
    }
    return deleted
}

export  function check_one_line_note_col(sudoku_arr: SudokuTable): boolean {
    let deleted: boolean = false;

    // loop for 9 quare
    for (let i = 0; i < 9; i++) {
        let new_i = Math.floor(i / 3) * 3;
        let new_j = i % 3 * 3;



        // loop for 9 numbers
        for (let c = 0; c < 9; c++) {
            let col_num_sum = 0;
            let col_index = 0
            let col_contain_cell:number[] = [];


            for (let a = 0; a < 3; a++) {
                let one_line_num = 0

                for (let b = 0; b < 3; b++) {
                    const pos_i = new_i + b
                    const pos_j = new_j + a

                    if (sudoku_arr[pos_i][pos_j].length !== 1 && sudoku_arr[pos_i][pos_j].includes(c + 1)) {
                        one_line_num++
                        col_contain_cell.push(pos_i)
                    }
                }
                if (1 <= one_line_num) {

                    one_line_num = 0;
                    col_num_sum++;
                    col_index = a
                }
            }
            if (col_num_sum === 1 && col_contain_cell.length !== 1) {
                if (delete_col_filter(sudoku_arr, [c + 1], col_contain_cell, new_j + col_index)) {
                    deleted = true
                    return deleted
                }
            }
            col_num_sum = 0
            col_index = 0
            col_contain_cell = []
        }
    }
    return deleted
}

export function note_triple_row(sudoku_arr: SudokuTable): boolean {
    for (let a = 0; a < sudoku_arr.length; a++) {

        for (let i = 0; i < sudoku_arr[a].length; i++) {
            for (let j = i + 1; j < sudoku_arr[a].length; j++) {
                for (let k = j + 1; k < sudoku_arr[a].length; k++) {
                    const selectedArrays = [sudoku_arr[a][i], sudoku_arr[a][j], sudoku_arr[a][k]];

                    if (selectedArrays.some(arr => arr.length === 1)) {
                        continue;
                    }


                    const uniqueValues = new Set<number>();
                    for (const array of selectedArrays) {
                        for (const value of array) {
                            uniqueValues.add(value);
                        }
                    }

                    if (uniqueValues.size === 3) {
                        const tmp = Array.from(uniqueValues)

                        let changes = false
                        for (let index = 0; index < 9; index++) {
                            if (!(index === i || index === j || index === k)) {
                                const old_len = sudoku_arr[a][index].length
                                sudoku_arr[a][index] = sudoku_arr[a][index].filter(num => num !== tmp[0])
                                sudoku_arr[a][index] = sudoku_arr[a][index].filter(num => num !== tmp[1])
                                sudoku_arr[a][index] = sudoku_arr[a][index].filter(num => num !== tmp[2])
                                const new_len = sudoku_arr[a][index].length
                                if (old_len !== new_len) {
                                    changes = true
                                }

                            }
                        }
                        if (changes) {
                            return changes
                        }
                    }
                }
            }
        }
    }
    return false
}

// TODO: change like row
export  function note_triple_col(sudoku_arr: SudokuTable): boolean {
    let deleted: boolean = false
    let col_element: number[] = []
    let added = 0;
    let row_indexes: number[] = []

    for (let j = 0; j < sudoku_arr[0].length; j++) {
        for (let a = 0; a < 7; a++) {
            if (sudoku_arr[a][j].length === 1) {
                continue
            }
            for (let i = 0; i < sudoku_arr.length; i++) {
                if (sudoku_arr[i][j].length !== 1 && sudoku_arr[i][j].length < 4 && col_element.length === 0) {
                    row_indexes.push(i)
                    col_element = [...sudoku_arr[i][j]]
                } else if (sudoku_arr[i][j].length !== 1) {
                    const tmp_arr = [...new Set([...col_element, ...sudoku_arr[i][j]])];

                    if (tmp_arr.length < 4) {
                        col_element = tmp_arr
                        row_indexes.push(i)
                        added++
                    }
                    if (added === 2) {
                        if (delete_col_filter(sudoku_arr, col_element, row_indexes, i)) {
                            return true
                        }
                    }
                }
            }
            added = 0
            col_element = []
            row_indexes = []
        }
    }

    return deleted;
}

// TODO: change like row j
export  function note_triple_square(sudoku_arr: SudokuTable): boolean {
    let deleted: boolean = false
    let sqr_element: number[] = []
    let added = 0;
    let sqr_indexes: pos[] = []

    for (let square_index = 0; square_index < 9; square_index++) {
        const square_start_i_index = Math.floor(square_index / 3) * 3
        const square_start_j_index = square_index % 3 * 3

        for (let retry = 0; retry < 7; retry++) {
            const initial_a = Math.floor(retry / 3)
            let initial_b = retry % 3

            for (let a = initial_a; a < 3; a++) {
                if (a !== initial_a) {
                    initial_b = 0
                }
                for (let b = initial_b; b < 3; b++) {

                    const i = square_start_i_index + a
                    const j = square_start_j_index + b
                    if (sudoku_arr[i][j].length !== 1 && sudoku_arr[i][j].length < 4 && sqr_element.length === 0) {
                        sqr_indexes.push({i, j})
                        sqr_element = [...sudoku_arr[i][j]]
                    } else if (sudoku_arr[i][j].length !== 1) {
                        const tmp_arr = [...new Set([...sqr_element, ...sudoku_arr[i][j]])];

                        if (tmp_arr.length < 4) {
                            sqr_element = tmp_arr
                            sqr_indexes.push({i, j})
                            added++
                        }
                        if (added === 2) {
                            if (delete_sqr_filter(sudoku_arr, sqr_element, sqr_indexes, square_index)) {
                                return true
                            }
                        }
                    }
                }
            }
            added = 0
            sqr_element = []
            sqr_indexes = []
        }
    }

    return deleted
}

// ============================================================================================
function findYWingPincer2(sudoku_arr: SudokuTable, pivot: number[], pincer1: number[], posiPivot: number, posjPivot: number, i: number, j: number, pincer1InRow: boolean): boolean {
    const set1 = new Set(pivot);
    const set2 = new Set(pincer1);

    // 중복을 제거한 요소들을 담을 배열을 생성합니다.
    const result: number[] = [];

    // 첫 번째 배열에서 중복이 아닌 요소를 추가합니다.
    for (const item of set1) {
        if (!set2.has(item)) {
            result.push(item);
        }
    }

    // 두 번째 배열에서 중복이 아닌 요소를 추가합니다.
    for (const item of set2) {
        if (!set1.has(item)) {
            result.push(item);
        }
    }
    result.sort((a, b) => a - b);

    // console.log("res", result);

    if (pincer1InRow) {
        // find pincer2 in col
        // console.log("check in col");

        for (let row = 0; row < 9; row++) {
            if (row !== i && sudoku_arr[row][j].length === 2) {
                if (result[0] === sudoku_arr[row][j][0] && result[1] === sudoku_arr[row][j][1]) {
                    if (sudoku_arr[row][posjPivot].length !== 1) {
                        const duplicates: number[] = pivot.filter(element => sudoku_arr[row][j].includes(element));

                        const filteredArray: number[] = sudoku_arr[row][posjPivot].filter(element => element !== duplicates[0]);
                        sudoku_arr[row][posjPivot] = filteredArray
                        return true

                    }
                }

            }
        }
    } else  {
        for (let col = 0; col < 9; col++) {
            if (col !== j && sudoku_arr[i][col].length === 2) {
                if (result[0] === sudoku_arr[i][col][0] && result[1] === sudoku_arr[i][col][1]) {
                    if (sudoku_arr[posiPivot][col].length !== 1) {
                        const duplicates: number[] = pivot.filter(element => sudoku_arr[i][col].includes(element));
                        const filteredArray: number[] = sudoku_arr[posiPivot][col].filter(element => element !== duplicates[0]);
                        sudoku_arr[posiPivot][col] = filteredArray
                        return true

                    }
                }
            }
        }
    }
    return false

}

function findYWingPincer1(sudoku_arr: SudokuTable, pivot: number[], i: number, j: number): boolean {

    // check in col
    for (let row = i; row < 9; row++) {
        if (row !== i && sudoku_arr[row][j].length === 2 && (sudoku_arr[row][j].includes(pivot[0]) || sudoku_arr[row][j].includes(pivot[1])) && !(sudoku_arr[row][j][0] === pivot[0] && sudoku_arr[row][j][1] === pivot[1])) {
            if (findYWingPincer2(sudoku_arr, pivot, sudoku_arr[row][j], i, j, row, j, false)) {
                return true
            }
        }
    }
    // check in row
    for (let col = j; col < 9; col++) {
        if (col !== j && sudoku_arr[i][col].length === 2 && (sudoku_arr[i][col].includes(pivot[0]) || sudoku_arr[i][col].includes(pivot[1])) && !(sudoku_arr[i][col][0] === pivot[0] && sudoku_arr[i][col][1] === pivot[1])) {
            if (findYWingPincer2(sudoku_arr, pivot, sudoku_arr[i][col], i, j, i, col, true)) {
                return true
            }
        }

    }
    return false
}

export function find_y_wing(sudoku_arr: SudokuTable): {table: SudokuTable, found: boolean} {
    // console.log("find_y_wing");

    let pivot: number[] = []

    for (let i = 0; i < 9; i++) {
        for (let j = 0; j < 9; j++) {
            if (sudoku_arr[i][j].length === 2) {
                pivot = sudoku_arr[i][j]
                if (findYWingPincer1(sudoku_arr, pivot, i, j)) {
                    console.log("set y");

                    return {table: sudoku_arr, found: true}
                    // return true
                }
            }
        }
    }
    return {table: sudoku_arr, found: false}
}

function findDuplicateArrays(arrays: number[][]): number[][] {
    const uniqueElements: Map<string, number[]> = new Map();
    const duplicates: number[][] = [];

    arrays.forEach(array => {
      // 빈 배열이면 무시
      if (array.length === 0) return;

      // 배열을 문자열로 변환하여 Map의 키로 사용
      const key: string = JSON.stringify(array);
      if (uniqueElements.has(key)) {
        // 이미 등록된 키라면 중복 배열로 추가
        if (!duplicates.some(item => JSON.stringify(item) === key)) {
          duplicates.push(array);
        }
      } else {
        uniqueElements.set(key, array);
      }
    });

    return duplicates;
  }


function findAllIndexesOfArray(arrays: number[][], targetArray: number[]): number[] {
    const indexes: number[] = [];

    // 배열을 순회하면서 각 요소와 대상 배열을 비교
    for (let index = 0; index < arrays.length; index++) {
      if (arrays[index].length === targetArray.length && arrays[index].every((value, idx) => value === targetArray[idx])) {
        indexes.push(index);  // 일치하는 경우 인덱스를 결과 배열에 추가
      }
    }

    return indexes;  // 일치하는 모든 인덱스의 배열 반환
  }

export function find_x_wing(sudoku_arr: SudokuTable, isSolving: boolean): { table: SudokuTable, found: boolean } {
    let copy_table: SudokuTable = JSON.parse(JSON.stringify(sudoku_arr))

    for (let num = 1; num < 10; num++) {
        // find in col
        let row_duo = []
        for (let i = 0; i < 9; i++) {
            let col_index = []
            for (let j = 0; j < 9; j++) {
                if (copy_table[i][j].includes(num)) {
                    col_index.push(j)
                }
            }

            if (col_index.length === 2) {
                row_duo.push(col_index)
            } else {
                row_duo.push([])
            }
            col_index = []
        }
        const dup_row = findDuplicateArrays(row_duo)
        if (dup_row.length !== 0) {
            const index = findAllIndexesOfArray(row_duo, JSON.parse(JSON.stringify(dup_row[0])))
            let changes: boolean = false
            for (let i = 0; i < 2; i++) {
                for (let j = 0; j < 9; j++) {
                    if (!index.includes(j)) {
                        const old_len = copy_table[j][dup_row[0][i]].length
                        copy_table[j][dup_row[0][i]] = copy_table[j][dup_row[0][i]].filter(num_tab => num_tab !== num)
                        const new_len = copy_table[j][dup_row[0][i]].length
                        if (old_len !== new_len) {
                            changes = true
                        }
                    }
                }
            }
            if (changes) {
                if (isSolving) {
                    console.log("x wing solved");
                    return {table: copy_table, found: true}
                } else {
                    console.log("x wing found");
                    return {table: sudoku_arr, found: true}
                }
            }
        }

        // find in row
        let col_duo = []
        for (let j = 0; j < 9; j++) {
            let row_index = []
            for (let i = 0; i < 9; i++) {
                if (copy_table[i][j].includes(num)) {
                    row_index.push(i)
                }
            }
            if (row_index.length === 2) {
                col_duo.push(row_index)
            } else {
                col_duo.push([])
            }
            row_index = []
        }
        const dup_col = findDuplicateArrays(col_duo)
        if (dup_col.length !== 0) {
            const index = findAllIndexesOfArray(col_duo, JSON.parse(JSON.stringify(dup_col[0])))
            let changes: boolean = false
            for (let j = 0; j < 2; j++) {
                for (let i = 0; i < 9; i++) {
                    if (!index.includes(i)) {
                        const old_len = copy_table[dup_col[0][j]][i].length
                        copy_table[dup_col[0][j]][i] = copy_table[dup_col[0][j]][i].filter(num_tab => num_tab !== num)
                        const new_len = copy_table[dup_col[0][j]][i].length
                        if (old_len !== new_len) {
                            changes = true
                        }
                    }
                }
            }
            if (changes) {
                if (isSolving) {
                    console.log("x wing solved");
                    return {table: copy_table, found: true}
                } else {
                    console.log("x wing found");
                    return {table: sudoku_arr, found: true}
                }
            }
        }

    }
    console.log("x wing not found");
    return {table: sudoku_arr, found: false}
}

// export  function basic_solving(sudoku_arr: SudokuTable) {

// }
