import {
    SudokuTable,
    set_alone,
    set_duo_row,
    set_duo_col,
    set_duo_square,
    find_alone_row,
    find_alone_col,
    check_one_line_note_col,
    check_one_line_note_row,
    note_triple_row,
    note_triple_col,
    note_triple_square,
    find_alone_sqare
} from '../sudoku';

export function solveSudoku(table: SudokuTable) {
    let changing = true;

    outerLoop: while (true) {
        changing = false;

        // delete for alone
        while (set_alone(table)) {
            continue outerLoop;
        }

        // obvious duo
        if (set_duo_row(table)) {
            continue;
        }
        if (set_duo_col(table)) {
            continue;
        }
        if (set_duo_square(table)) {
            continue;
        }

        // obvious triple
        while (note_triple_row(table)) {
            continue outerLoop;
        }
        while (note_triple_col(table)) {
            continue outerLoop;
        }
        while (note_triple_square(table)) {
            continue outerLoop;
        }

        // obvious / hidden single
        if (find_alone_row(table)) {
            continue;
        }
        if (find_alone_col(table)) {
            continue;
        }
        if (find_alone_sqare(table)) {
            continue;
        }

        // pointing pairs / triple
        if (check_one_line_note_col(table)) {
            continue;
        }
        while (check_one_line_note_row(table)) {
            continue outerLoop;
        }

        if (!changing) break;
    }

    return table
}