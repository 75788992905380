import React, { useEffect, useState } from 'react';

import './a.scss';

import { check_correct_answer, SudokuTable, set_all_note, set_alone, find_y_wing, find_x_wing } from './sudoku';

import { getRandomSudoku } from './utils/getRandomSudoku';
import { solveSudoku } from './utils/solveSudoku';

function App() {
    const [recordTable, setRecordTable] = useState<SudokuTable[]>([]);
    const [recordIndex, setRecordIndex] = useState<number>(0);
    const [refSudoku, setRefSudoku] = useState<SudokuTable>([
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
        [[], [], [], [], [], [], [], [], []],
    ]);
    const [repSudoku, setRepSudoku] = useState<string>('');

    const [posI, setPosI] = useState(0);
    const [posJ, setPosJ] = useState(0);
    const [isNoting, setIsNoting] = useState(false);
    const [foundX, setFoundX] = useState(false)
    const [foundY, setFoundY] = useState(false)

    const handleButtonClick = async () => {
        // const randomSudoku = await getRandomSudoku(); // 995 928 이상

            const randomSudoku = {
        "id": 277,
        "mission": "300000000060007910000003250019064500000000060085000000000800000001009700470050000",
        "solution": "342915687568427913197683254219764538734598162685132479926871345851349726473256891",
        "win_rate": 29.51,
        "not_finish": true
    }

        console.log('sudoku id is:', randomSudoku.id);

        let mission = randomSudoku.mission;
        setRepSudoku(randomSudoku.solution);

        let sudokuTable: SudokuTable = [
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            // -----------------------------------------------------
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            // -----------------------------------------------------
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
            [[], [], [] /**/, [], [], [] /**/, [], [], []],
        ];

        for (let i = 0; i < 9; i++) {
            for (let j = 0; j < 9; j++) {
                sudokuTable[i][j].push(Number(mission[i * 9 + j]));
            }
        }

        const filledTable: SudokuTable = JSON.parse(JSON.stringify(sudokuTable));

        set_all_note(filledTable);

        const firstSolveSudoku = solveSudoku(filledTable);
        setRefSudoku(firstSolveSudoku);
        setRecordTable([firstSolveSudoku]);
    };

    useEffect(() => {
        handleButtonClick();
    }, []);

    function clickFindX() {
        const { found } = find_x_wing(JSON.parse(JSON.stringify(refSudoku)), false)
        console.log(found);
        setFoundX(found)
    }

    function clickFindY() {
        const { found } = find_y_wing(JSON.parse(JSON.stringify(refSudoku)))
        console.log(found);
        setFoundX(found)
    }

    function handleKeyDown(event: KeyboardEvent) {
        const pressedKey = event.key;

        if (pressedKey === 'y') {
            console.log('find y...');
            setRefSudoku(find_y_wing(JSON.parse(JSON.stringify(refSudoku))).table);
        }
        if (pressedKey === 't') {
            console.log('test');
        }
        if (pressedKey.toLocaleLowerCase() === 'x') {
            if (event.shiftKey) {
                setRefSudoku(find_x_wing(JSON.parse(JSON.stringify(refSudoku)), true).table);
            } else {
                setRefSudoku(find_x_wing(JSON.parse(JSON.stringify(refSudoku)), false).table);
            }
        }
        if (pressedKey === 'z') {
            if (event.metaKey) {
                if (event.shiftKey) {
                    if (recordIndex !== recordTable.length - 1) {
                        const tmpTable = JSON.parse(JSON.stringify(recordTable[recordIndex + 1]));

                        setRecordIndex(recordIndex + 1);
                        setRefSudoku(tmpTable);
                    }
                    return;
                }
                if (recordIndex !== 0) {
                    setRecordIndex(recordIndex - 1);
                    setRefSudoku(recordTable[recordIndex - 1]);
                }
                return;
            }
        }
        if (pressedKey === 'ArrowUp') {
            if (posI !== 0) {
                setPosI(posI - 1);
            }
            return;
        }
        if (pressedKey === 's') {
            const solved = solveSudoku(JSON.parse(JSON.stringify(refSudoku)));
            setRefSudoku(solved);
            check_correct_answer(solved, repSudoku);
            return;
        }

        if (pressedKey === ' ') {
            setIsNoting(!isNoting);
            return;
        }
        if (pressedKey === 'ArrowDown') {
            if (posI !== 8) {
                setPosI(posI + 1);
            }
            return;
        }
        if (pressedKey === 'ArrowLeft') {
            if (posJ !== 0) {
                setPosJ(posJ - 1);
            }
            return;
        }
        if (pressedKey === 'ArrowRight') {
            if (posJ !== 8) {
                setPosJ(posJ + 1);
            }
            return;
        }

        if (0 < Number(pressedKey) && Number(pressedKey) < 10) {
            if (refSudoku[posI][posJ].length !== 1) {
                if (isNoting) {
                    let index = refSudoku[posI][posJ].indexOf(Number(pressedKey));
                    if (index !== -1) {
                        let aaa: SudokuTable = JSON.parse(JSON.stringify(refSudoku));
                        aaa[posI][posJ].splice(index, 1);
                        setRefSudoku(aaa);
                    }
                    return;
                }
                if (repSudoku[posI * 9 + posJ] === pressedKey) {
                    const currentTable = JSON.parse(JSON.stringify(refSudoku));

                    currentTable[posI][posJ] = [Number(pressedKey)];
                    const aaas = JSON.parse(JSON.stringify(currentTable));
                    while (set_alone(aaas)) {}
                    setRefSudoku(aaas);

                    check_correct_answer(aaas, repSudoku);

                    const nowRecord = JSON.parse(JSON.stringify(recordTable));
                    nowRecord.push(aaas);
                    setRecordTable(nowRecord);
                    setRecordIndex(nowRecord.length - 1);
                } else {
                    console.log('wrong', pressedKey);
                }
            }
        }
    }

    function clickNumber(clickedNum: number) {
        if (isNoting && refSudoku[posI][posJ].length !== 1) {
            refSudoku[posI][posJ] = refSudoku[posI][posJ].filter(element => element !== clickedNum)
            const aaas = JSON.parse(JSON.stringify(refSudoku));
            setRefSudoku(aaas);
            return
        }
        if (repSudoku[posI * 9 + posJ] === String(clickedNum)) {
            refSudoku[posI][posJ] = [clickedNum];
            const aaas = JSON.parse(JSON.stringify(refSudoku));
            while (set_alone(aaas)) {}
            setRefSudoku(aaas);

            check_correct_answer(aaas, repSudoku);
        } else {
            console.log('wrong', clickedNum);
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    });

    function checkIsHightLight(i: number, j: number): boolean {
        if (posI === i || posJ === j) {
            return true;
        }

        const sqr_start_pos_i = Math.floor(i / 3) * 3;
        const sqr_start_pos_j = Math.floor(j / 3) * 3;

        if (sqr_start_pos_i <= posI && posI <= sqr_start_pos_i + 2 && sqr_start_pos_j <= posJ && posJ <= sqr_start_pos_j + 2) {
            return true;
        }
        return false;
    }

    function clickCell(i: number, j: number) {
        setPosI(i);
        setPosJ(j);
    }
    const repeatedItems = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    return (
        <div>
            <div className="training">
                <div className="sudoku-table">
                    {refSudoku.map((row, i) => (
                        <div className="row" key={i}>
                            {row.map((cell, j) => (
                                <div
                                    className={`cell ${checkIsHightLight(i, j) ? 'hight-light' : ''} ${
                                        i === posI && j === posJ ? 'target' : ''
                                    }`}
                                    onClick={() => {
                                        clickCell(i, j);
                                    }}
                                    key={j}
                                >
                                    {cell.length === 1 ? (
                                        <div
                                            className={`alone ${
                                                refSudoku[posI][posJ].length === 1 && refSudoku[posI][posJ][0] === cell[0] ? 'target-num' : ''
                                            }`}
                                        >
                                            {cell[0]}
                                        </div>
                                    ) : (
                                        <div className="multy">
                                            {repeatedItems.map((num, index) =>
                                                cell.includes(num) ? (
                                                    <div
                                                        className={`${
                                                            refSudoku[posI][posJ].length === 1 && refSudoku[posI][posJ][0] === num
                                                                ? 'target-num'
                                                                : ''
                                                        }`}
                                                        key={`num-${index}`}
                                                    >
                                                        {num}
                                                    </div>
                                                ) : (
                                                    <div className="empty" key={`num-${index}`}></div>
                                                ),
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className='number-section'>
                <div className='numbers'>
                    <div className='number' onClick={() => clickNumber(1)}>1</div>
                    <div className='number' onClick={() => clickNumber(2)}>2</div>
                    <div className='number' onClick={() => clickNumber(3)}>3</div>
                    <div className='number' onClick={() => clickNumber(4)}>4</div>
                    <div className='number' onClick={() => clickNumber(5)}>5</div>
                    <div className='number' onClick={() => clickNumber(6)}>6</div>
                    <div className='number' onClick={() => clickNumber(7)}>7</div>
                    <div className='number' onClick={() => clickNumber(8)}>8</div>
                    <div className='number' onClick={() => clickNumber(9)}>9</div>
                </div>
            </div>

            <div className='solving'>
                <div onClick={() => {clickFindX()}} style={{ width: '60px', height: '60px', backgroundColor: 'blue' }}>
                    <div>
                        x
                    </div>
                    <div>
                        {foundX && "found"}
                    </div>
                </div>
                <div onClick={() => { clickFindY()}} style={{ width: '60px', height: '60px', backgroundColor: 'blue' }}>
                    <div>
                        y
                    </div>
                    <div>
                        {foundY && "found"}
                    </div>
                </div>
                <div style={{ width: '60px', height: '60px', backgroundColor: 'blue' }}>solve (s)</div>
                <div onClick={() => setIsNoting(!isNoting)} style={{ width: '60px', height: '60px', backgroundColor: 'blue', marginLeft: '20px' }}>
                    <div>note</div>
                    {isNoting && <div> is noting</div>}
                </div>
            </div>
        </div>
    );
}

export default App;
